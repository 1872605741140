<template>
  <div class="mibi-card">
    <MiTitle :title="$t('recharge.mibiTitle')" />
    <div class="body">
      <div class="card"></div>
      <h2 class="title">{{ $t('recharge.mibiPwd') }}</h2>
      <MiInput
        :placeholder="$t('recharge.mibiPlaceholder')"
        v-model="voucherCode"
        @focus="fixed = false"
        @blur="blurHandle"
      />
      <MiFooter
        :fixed="fixed"
        :disabled="disabled"
        :content="$t('common.rechargeBtn')"
        @click="recharge"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { MiTitle, MiInput, MiFooter } from '@/components'

export default {
  components: {
    MiTitle,
    MiInput,
    MiFooter
  },
  data() {
    return {
      voucherCode: '',
      fixed: true
    }
  },
  computed: {
    disabled() {
      return this.voucherCode.length === 0
    },
    ...mapState({
      userId: state => state.about.userInfo?.userId
    })
  },
  methods: {
    blurHandle() {
      setTimeout(() => {
        this.fixed = true
      }, 10)
    },
    recharge() {
      const { userId, voucherCode } = this

      this.$http.voucherRecharge({
        userId,
        voucherCode
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.mibi-card {
  height: 100%;
  .body {
    padding: 0 37px 0;
    .card {
      margin: 60px 0;
      height: 494px;
      background: {
        image: url(https://ts.market.mi-img.com/thumbnail/png/q80/Mibi/098941879d2ec4831b1860f03a189660274902797);
        repeat: no-repeat;
        size: auto 594px;
        position: center;
      }
      border-radius: 45px;
    }
    .title {
      padding-left: 44px;
      color: #8c93b0;
      font-size: 36px;
      margin-bottom: 34px;
    }
    .mi-input {
      margin-bottom: 300px;
    }
  }
}
</style>
